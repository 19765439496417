/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import { Button, SimpleGrid, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '../../components/Card';
import HeaderCard from '../../components/HeaderCard';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import { CARDS_DATA, PAGE_TITLE, TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getCompliances, getCompliancesCountData } from '../../redux/action/compliance.action';
import { StyledContainer } from '../AdminDashboard/AdminDashboard.styled';
import StyledComplianceContainer from './Compliance.styled';
import Loader from '../../components/Loader';

const INITIAL_COLUMNS = [
  {
    label: 'Employee ID',
    field: 'employee_id',
    sort: true
  },
  {
    label: 'First Name',
    field: 'first_name',
    sort: true
  },
  {
    label: 'Agency',
    field: 'agency_name',
    sort: true
  },
  {
    label: 'Site',
    field: 'site_name',
    sort: true
  },
  {
    label: 'Shift',
    field: 'shift_name',
    sort: true
  },
  {
    label: 'Role',
    field: 'job_name',
    sort: true
  },
  {
    label: 'DOB',
    field: 'date_of_birth',
    sort: true
  },
  {
    label: 'Post Code',
    field: 'post_code',
    sort: true
  }
];
const Campliance = function () {
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const toast = useToast();

  const [reload, setReload] = useState(false);
  const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)');
  const [selectedCard, setSelectedCard] = useState(0);
  const { selected_start_date } = useSelector((state) => state.agency);
  const { selected_end_date } = useSelector((state) => state.agency);
  const [columns, setcolumns] = useState([...INITIAL_COLUMNS]);
  const [count, setcount] = useState(0);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [paramsData, setparamsData] = useState([]);
  const [countData, setcountData] = useState();
  const [cardData, setcardData] = useState(CARDS_DATA);

  useEffect(() => {
    document.title = PAGE_TITLE.COMPLIANCE;
  }, []);

  const handleCardClick = (e, index) => {
    if (isPageLoader) {
      e.preventDefault();
    } else {
      setcount(count + 1);
      setSelectedCard(index);
    }
  };

  const downloadComplianceDataClickHandler = async () => {
    const params = paramsData;
    params[0] = countData;
    params[1] = 1;

    const result = await getCompliances(
      selected_client_id || userDetails.client_id,
      (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) &&
        userDetails.agency_id,
      userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.REGION_ADMIN
        ? userDetails.region_id
        : '',
      userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : '',
      selectedCard,
      selected_start_date,
      selected_end_date,
      ...params
    );

    if (result && result.ok) {
      let line = `Employee Id,First Name,Agency,Site,Shift,Role,DOB,Post Code${'\r\n'}`;
      result.data.workers.forEach((row) => {
        line += `${row.employee_id},${row.first_name},${row.agency_name},${row.site_name},${
          row.shift_name
        },${row.job_name},${row.date_of_birth},${row.post_code},${'\r\n'}`;
      });
      const csvContent = `data:text/csv;charset=utf-8,${line}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Compliance Data Of Workers.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const retrieveComplianceData = async (...params) => {
    setparamsData(params);
    const result = await getCompliances(
      selected_client_id || userDetails.client_id,
      (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) &&
        userDetails.agency_id,
      userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.REGION_ADMIN
        ? userDetails.region_id
        : '',
      userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : '',
      selectedCard,
      selected_start_date,
      selected_end_date,
      ...params
    );
    if (result.ok) {
      setcountData(result.data.count);
      return result;
    }
    return {
      data: []
    };
  };
  const retriveCountData = async () => {
    const result = await getCompliancesCountData(
      selected_client_id || userDetails.client_id,
      (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) &&
        userDetails.agency_id,
      userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.REGION_ADMIN
        ? userDetails.region_id
        : '',
      userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : '',

      selected_start_date,
      selected_end_date
    );
    setIsPageLoader(false);
    if (result.ok) {
      const updatedCARDS_DATA = cardData.map((card) => {
        const matchingData = result.data.count.find((item) => item.id === card.index);
        if (matchingData) {
          card.value = matchingData.count;
        }
        return card;
      });
      setcardData(updatedCARDS_DATA);
    }
  };

  useEffect(() => {
    setIsPageLoader(true);
    retriveCountData();
    setReload(!reload);
  }, [selected_start_date, selected_end_date, selectedCard, selected_client_id]);
  return (
    <StyledComplianceContainer bg="main.secondary">
      {isPageLoader ? <Loader /> : null}
      <StyledContainer>
        <SimpleGrid
          width={isSmallerThan1440 && '1190px'}
          //   height="250px"
          minChildWidth="220px">
          {cardData.map((card, index) => (
            <HeaderCard
              textAlign="center"
              fontWeight="semibold"
              disabled="true"
              width="220px"
              height="220px"
              //   p={4}
              cursor={isPageLoader ? 'not-allowed' : 'pointer'}
              key={card.index}
              bg={selectedCard === card.index ? 'main.semiPrimary' : 'main.white'}
              color={selectedCard === card.index ? 'main.white' : ''}
              onClick={(e) => handleCardClick(e, card.index)}>
              <Text fontSize="lg" fontWeight="normal">
                {card.title}
              </Text>
              <Text
                fontSize="6xl"
                color={selectedCard === card.index ? 'main.white' : 'main.semiPrimary'}
                fontWeight="normal">
                {card.value}
              </Text>
            </HeaderCard>
          ))}
        </SimpleGrid>
      </StyledContainer>
      {selectedCard > 0 && (
        <Card style={{ marginTop: '20px' }}>
          <Button
            mr="8px"
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            disabled={countData === 0}
            onClick={downloadComplianceDataClickHandler}>
            Export
          </Button>
          <ServerSideDatatable
            pageSize={10}
            initialSortKey="first_name"
            column={columns}
            onPaginate={retrieveComplianceData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[10, 15]}
            dataKey="workers"
            refresh={reload}
            moveToFirstPage="true"
          />
        </Card>
      )}
    </StyledComplianceContainer>
  );
};

export default Campliance;
