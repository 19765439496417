/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { CHART_COLORS_FOR_AGENCY, NEW_CHART_COLOR } from '../../constants';
import { colors } from '../../theme';

const ColumnChart = function ({
  height = '100%',
  header,
  categories,
  series,
  horizontal,
  customColor = true,
  categoriesVisible = false,
  percentage,
  bottomDeck
}) {
  const colorArray = categories.map(() => colors.main.blueBackground);
  const newSeries = series;
  // const [dynamicWidth, setDynamicWidth] = useState(
  //   window.innerWidth <= 1440
  //     ? categories?.length * 40 > 190
  //       ? 190
  //       : `${categories?.length * 40}px`
  //     : categories?.length * 60 > 350
  //     ? '90%'
  //     : `${categories?.length * 60}px`
  // );

  // const setWidth = () => {
  //   if (window.innerWidth <= 1440) {
  //     setDynamicWidth(categories?.length * 40 > 190 ? '190px' : `${categories?.length * 40}px`);
  //   } else {
  //     setDynamicWidth(categories?.length * 60 > 350 ? '90%' : `${categories?.length * 60}px`);
  //   }
  // };

  // useLayoutEffect(() => {
  //   window.addEventListener('resize', setWidth);
  //   return () => window.removeEventListener('resize', setWidth);
  // }, []);

  // useEffect(() => {}, [dynamicWidth]);

  const [options] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    title: {
      text: header,
      align: 'center'
    },
    plotOptions: {
      bar: {
        borderRadius: 7,
        columnWidth: 50,
        horizontal,
        colors: {
          backgroundBarColors: colorArray,
          backgroundBarRadius: 7
        }
      }
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        return customColor ? NEW_CHART_COLOR.BLUE : '#2572FF';
      }
    ],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories,
      labels: {
        show: categoriesVisible
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      max: bottomDeck && 100,
      min: bottomDeck && 0,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom({ series, seriesIndex, dataPointIndex, w }) {
        return `${
          bottomDeck
            ? categories[dataPointIndex]
              ? `<div class="tooltip">  <div class="tooltip-inside"> ${
                  series[seriesIndex][dataPointIndex] !== 0
                    ? `<span class="tooltip-circle" style="background-color: ${
                        customColor ? NEW_CHART_COLOR.BLUE : '#2572FF'
                      }"></span>`
                    : ''
                } <p style="max-width: 110px; word-break: break-word;
  white-space: normal;">${categories[dataPointIndex]}</p></div>  <p>${
                  newSeries ? newSeries[dataPointIndex] : ''
                }  ${series ? '&' : ''} ${series[seriesIndex][dataPointIndex]} ${
                  series ? '%' : ''
                }</p> </div>`
              : ''
            : categories[dataPointIndex]
            ? `<div class="tooltip">  <div class="tooltip-inside"> ${
                series[seriesIndex][dataPointIndex] !== 0
                  ? `<span class="tooltip-circle" style="background-color: ${
                      customColor ? NEW_CHART_COLOR.BLUE : '#2572FF'
                    }"></span>`
                  : ''
              } <p style="max-width: 110px; word-break: break-word;
    white-space: normal;">${categories[dataPointIndex]}</p></div>  <p>${
                series[seriesIndex][dataPointIndex]
              }  ${percentage ? '&' : ''} ${percentage ? percentage[dataPointIndex] : ''}${
                percentage ? '%' : ''
              }</p> </div>`
            : ''
        } 
        `;
      }
    }
  });
  return (
    <ReactApexChart
      height={height}
      width="100%"
      // width={`${categories?.length * 60 > 350 ? 350 : categories?.length * 60}px`}
      // width={`${categories?.length * 60}px`}
      options={options}
      series={bottomDeck ? percentage : series}
      type="bar"
    />
  );
};

export default ColumnChart;
