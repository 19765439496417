/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { combineReducers } from 'redux';
import authentication from './authentication.reducer';
import agency from './agency.reducer';
import translate from './translate.reducer';

const appReducer = combineReducers({
  authentication,
  agency,
  translate
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

// const appReducer = combineReducers({
//   authentication,
//   agency
// });

// export default rootReducer = (state, action) => {
//   if (action.type === LOGOUT) {
//     state = undefined;
//   }

//   return appReducer(state, action);
// };

// // export default rootReducer;
