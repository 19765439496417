/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Flex, Image, Switch, Text, useToast } from '@chakra-ui/react';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import constants, {
  ADD_ASSOCIATION_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION,
  BooleanValue
} from '../../constants';
import {
  addAssociation,
  getAssociations,
  updateAssociation,
  getClientAssociations,
  getAgencyAssociations,
  updateSwitchDataInAssociation,
  updateRestrictCommentSwitch,
  updateTapDataInAssociation
} from '../../redux/action/association.action';
import ModalComponent from '../../components/Modal';
import {
  allowTwoDecimalNumbers,
  currancyFormatter,
  deepClone,
  validateForm
} from '../../utils/helper';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import CompanyDropdown from '../../components/Forms/CompanyDropdown';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import InputComponent from '../../components/Input';
import CurrencyDropdown from '../../components/Forms/CurrencyDropdown';
import { getModulePermissions } from '../../utils/hooks';
import Loader from '../../components/Loader';
import StyledAgencyContainer from './Association.styled';
import Dropdown from '../../components/Dropdown';
import SiteSelectionModal from '../../components/SiteSelectionModal/SiteSelectionModal';
import AddIcon from '../../assets/images/add.png';
import SiteMargins from '../../components/SiteMargins/SiteMargins';

const Associations = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.ASSOCIATIONS;
  }, []);

  const toast = useToast();
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const permission = getModulePermissions(PATH.AGENCY_CLIENT.CODE);
  const [refresh, setRefresh] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_ASSOCIATION_VALIDATIONS));
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [company, setCompany] = useState({});
  const [agency, setAgency] = useState({});
  const [currency, setCurrency] = useState({});
  const [isPageLoader, setIsPageLoader] = useState(false);

  const [data, setData] = useState({
    client_id: '',
    agency_id: '',
    margin: '',
    overtime_margin: '',
    transport_fee: '',
    training_margin: '',
    ssp: '',
    currency: null,
    bh_margin: '',
    nsp_margin: '',
    internal_standard_margin: '',
    internal_overtime_margin: '',
    internal_permanent_margin: '',
    suspension_margin: '',
    holiday_activation: false,
    holiday_cost_removed: false
  });
  const [holidayCostRemovedDisable, setHolidayCostRemovedDisable] = useState(true);

  const [isSiteModalOpen, setSiteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const onModalClose = () => {
    setModalOpen(false);
    setCompany({});
    setAgency({});
    setCurrency({});
    setRefresh(!refresh);
    setData({
      client_id: '',
      agency_id: '',
      margin: '',
      overtime_margin: '',
      transport_fee: '',
      training_margin: '',
      ssp: '',
      currency: null,
      bh_margin: '',
      nsp_margin: '',
      internal_standard_margin: '',
      internal_overtime_margin: '',
      internal_permanent_margin: '',
      suspension_margin: '',
      holiday_activation: false,
      holiday_cost_removed: false
    });
    setHolidayCostRemovedDisable(true);
    setValidations(deepClone(ADD_ASSOCIATION_VALIDATIONS));
    setEdit(false);
  };

  const saveAssociation = async () => {
    const dataToBeSaved = { ...data };
    if (userDetails.user_type_id !== USER_TYPE.SUPER_ADMIN) {
      delete dataToBeSaved.holiday_activation;
      delete dataToBeSaved.holiday_cost_removed;
    }
    const [validationObject, isFormValid] = validateForm(dataToBeSaved, validations);
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        result = await updateAssociation(id, dataToBeSaved);
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setEdit(false);
          onModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        result = await addAssociation(dataToBeSaved);
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          onModalClose();
          setEdit(false);
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };
  const onToggleChanged = async (e, rowId, row) => {
    setId(rowId);
    const payload = {
      is_restricted: !row.is_restricted
    };
    const result = await updateSwitchDataInAssociation(row.id, payload);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      setReload((prev) => !prev);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const onTapToggleChanged = async (e, rowId, row) => {
    setId(rowId);
    const payload = {
      total_assignment_pay: !row.total_assignment_pay
    };
    const result = await updateTapDataInAssociation(row.id, payload);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      setReload((prev) => !prev);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const onRestrictCommentToggle = async (e, rowId, row) => {
    setId(rowId);
    const payload = {
      comment_restricted: !row.comment_restricted
    };
    const result = await updateRestrictCommentSwitch(row.id, payload);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      setReload((prev) => !prev);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const editData = (rowId, row) => {
    setEdit(true);
    setCompany({ label: row.client_name, value: row.client_id });
    setAgency({ label: row.agency_name, value: row.agency_id });
    setSelectedRow(row.id);
    setData({
      client_id: row.client_id,
      agency_id: row.agency_id,
      currency: row.currency,
      margin: row.margin.toString(),
      transport_fee: row.transport_fee,
      ssp: row.ssp,
      training_margin: row.training_margin,
      training_hours: row.training_hours,
      overtime_margin: row.overtime_margin,
      bh_margin: row.bh_margin,
      nsp_margin: row.nsp_margin,
      internal_standard_margin: row.internal_standard_margin,
      internal_overtime_margin: row.internal_overtime_margin,
      internal_permanent_margin: row.internal_permanent_margin,
      suspension_margin: row.suspension_margin,
      holiday_activation: Boolean(row.holiday_activation),
      holiday_cost_removed: Boolean(row.holiday_cost_removed)
    });
    if (row.holiday_activation) {
      setHolidayCostRemovedDisable(false);
    }
    setCurrency({
      label: row.currency ? row.currency.toUpperCase() : 'INR',
      value: row.currency ? row.currency.toUpperCase() : 'INR'
    });
    setModalOpen(true);
    setId(rowId);
  };

  const setCompanyData = (e) => {
    setCompany(e);
    setData({ ...data, client_id: e.value });
  };

  const setAgencyData = (e) => {
    setAgency(e);
    setData({ ...data, agency_id: e.value });
  };

  const setCurrencyData = (e) => {
    setCurrency(e);
    setData({ ...data, currency: e.value });
  };

  const handleMarginRuleClick = (row) => {
    setSelectedRow(() => row.id);
    setSiteModalOpen(true);
  };

  const [column, setColumn] = useState([
    {
      label: 'Margin',
      field: 'margin',
      sort: false,
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      }
    },
    ...(userDetails &&
    (userDetails.user_type_id === USER_TYPE.SUPER_ADMIN ||
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN)
      ? [
          {
            label: `Site Margin Rule's`,
            field: 'restricted_sites_count',
            sort: false,
            customRow: (cell, row) => {
              return (
                <Flex align="center">
                  <Image
                    mr="10px"
                    cursor="pointer"
                    src={AddIcon}
                    height={6}
                    onClick={() => handleMarginRuleClick(row)}
                  />
                  <Text fontSize="lg" fontWeight="bold" color="blue.600">
                    {cell} site{cell > 1 ? 's' : ''}
                  </Text>
                </Flex>
              );
            }
          }
        ]
      : [])
  ]);

  useEffect(() => {
    let tempArray = [];
    if (userDetails && userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      tempArray = [
        {
          label: 'Agency Name',
          field: 'agency_name',
          sort: true
        },
        ...column.filter((col) => col.label !== 'Agency Name')
      ];
    } else if (userDetails && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      tempArray = [
        {
          label: 'Client Name',
          field: 'client_name',
          sort: true
        },
        {
          label: 'Margin',
          field: 'margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Overtime Margin',
          field: 'overtime_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Transport Fee',
          field: 'transport_fee',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Miscellaneous',
          field: 'ssp',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Training Margin',
          field: 'training_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Bh Margin',
          field: 'bh_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'NSP Margin',
          field: 'nsp_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Internal Standard Margin',
          field: 'internal_standard_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Internal Overtime Margin',
          field: 'internal_overtime_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Internal Permanent Margin',
          field: 'internal_permanent_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        {
          label: 'Suspension Margin',
          field: 'suspension_margin',
          sort: false,
          customRow: (cell) => {
            return `${currancyFormatter(cell)}`;
          }
        },
        ...column.filter(
          (col) =>
            ![
              'Margin',
              'Client Name',
              'Overtime Margin',
              'Transport Fee',
              'Miscellaneous',
              'Training Margin',
              'Bh Margin',
              'NSP Margin',
              'Internal Standard Margin',
              'Internal Overtime Margin',
              'Internal Permanent Margin',
              'Suspension Margin'
            ].includes(col.label)
        )
      ];
    } else {
      tempArray = [
        {
          label: 'Client Name',
          field: 'client_name',
          sort: true
        },
        {
          label: 'Agency Name',
          field: 'agency_name',
          sort: true
        },
        ...column.filter((col) => !['Client Name', 'Agency Name'].includes(col.label))
      ];
    }

    if (permission && permission.includes('edit')) {
      tempArray = [
        ...tempArray.filter((col) => col.label !== 'Action'),
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(cell, row)}>
                {constants.ASSOCIATION.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ];
    }
    if (userDetails && userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      tempArray = [
        ...tempArray.filter(
          (col) =>
            ![
              'Restrict Agency',
              'Restrict Comment',
              'Total Assignment Pay',
              'Holiday Activation',
              'Holiday Cost Removed'
            ].includes(col.label)
        ),
        {
          label: 'Restrict Agency',
          field: 'is_restricted',
          customRow: (cell, row) => {
            return (
              <Switch
                ml="30px"
                id={cell}
                isChecked={row.is_restricted ? true : false}
                onChange={(e) => onToggleChanged(e, cell, row)}
              />
            );
          }
        },
        {
          label: 'Restrict Comment',
          field: 'comment_restricted',
          customRow: (cell, row) => {
            return (
              <Switch
                ml="30px"
                id={cell}
                isChecked={row.comment_restricted ? true : false}
                onChange={(e) => onRestrictCommentToggle(e, cell, row)}
              />
            );
          }
        },
        {
          label: 'Total Assignment Pay',
          field: 'total_assignment_pay',
          customRow: (cell, row) => {
            return (
              <Switch
                ml="30px"
                id={cell}
                isChecked={row.total_assignment_pay ? true : false}
                onChange={(e) => onTapToggleChanged(e, cell, row)}
              />
            );
          }
        },
        {
          label: 'Holiday Activation',
          field: 'holiday_activation',
          customRow: (cell, row) => {
            return (
              <Switch
                ml="30px"
                id={cell}
                isChecked={row.holiday_activation ? true : false}
                isDisabled
              />
            );
          }
        },
        {
          label: 'Holiday Cost Removed',
          field: 'holiday_cost_removed',
          customRow: (cell, row) => {
            return (
              <Switch
                ml="30px"
                id={cell}
                isChecked={row.holiday_cost_removed ? true : false}
                isDisabled
              />
            );
          }
        }
      ];
    }
    setColumn(tempArray);
  }, [userDetails, permission]);

  useEffect(async () => {
    setIsPageLoader(true);
  }, []);

  const handleSetMargin = () => {
    setSiteModalOpen(false);
  };

  return (
    <>
      <StyledAgencyContainer>
        {isPageLoader ? <Loader /> : null}
        <Card>
          {permission &&
            permission.includes('create') &&
            userDetails.user_type_id !== USER_TYPE.CLIENT_ADMIN && (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setModalOpen(true)}>
                {constants.ASSOCIATION.CREATE_BUTTON_TEXT}
              </Button>
            )}
          <ModalComponent
            isOpen={inModalOpan}
            onClose={() => onModalClose()}
            modalTitle={
              edit ? constants.ASSOCIATION.EDIT_HEADER_TEXT : constants.ASSOCIATION.HEADER_TEXT
            }
            modalContent={
              <Box p="10px">
                <Flex>
                  <StyledInputWrapper>
                    <CompanyDropdown
                      company={company}
                      validationObj={validations.client_id}
                      refresh={refresh}
                      setCompany={setCompanyData}
                      disabled={edit}
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper>
                    <AgencyDropdown
                      agency={agency}
                      validationObj={validations.agency_id}
                      refresh={refresh}
                      setAgency={setAgencyData}
                      disabled={edit}
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper>
                    <CurrencyDropdown
                      currency={currency}
                      validationObj={validations.currency}
                      refresh={refresh}
                      setCurrency={setCurrencyData}
                      disabled={edit}
                    />
                  </StyledInputWrapper>
                </Flex>
                {userDetails.user_type_id === USER_TYPE.SUPER_ADMIN && (
                  <Flex style={{ marginBottom: '10px' }}>
                    <StyledInputWrapper>
                      <Dropdown
                        label="Holiday Activation"
                        onSelect={(e) => {
                          const holiday_activation = JSON.parse(e.value);
                          if (holiday_activation) {
                            setHolidayCostRemovedDisable(false);
                          } else {
                            setHolidayCostRemovedDisable(true);
                          }
                          setData((prevData) => ({
                            ...prevData,
                            holiday_activation
                          }));
                        }}
                        options={BooleanValue}
                        value={{
                          label: data.holiday_activation.toString(),
                          value: data.holiday_activation.toString()
                        }}
                        placeholder=""
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper
                      style={{
                        paddingLeft: '10px'
                      }}>
                      <Dropdown
                        label="Holiday Cost Removed"
                        disabled={holidayCostRemovedDisable}
                        onSelect={(e) => {
                          setData({
                            ...data,
                            holiday_cost_removed: JSON.parse(e.value)
                          });
                        }}
                        options={BooleanValue}
                        value={{
                          label: data.holiday_cost_removed.toString(),
                          value: data.holiday_cost_removed.toString()
                        }}
                        placeholder=""
                      />
                    </StyledInputWrapper>
                  </Flex>
                )}
                <Flex>
                  <StyledInputWrapper style={{ marginRight: '8px' }}>
                    <InputComponent
                      lable={constants.STANDARD_MARGIN}
                      placeholder={constants.STANDARD_MARGIN}
                      validationObj={validations.margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.OVERTIME_MARGIN}
                      placeholder={constants.OVERTIME_MARGIN}
                      validationObj={validations.overtime_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          overtime_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.overtime_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper style={{ marginRight: '8px' }}>
                    <InputComponent
                      lable={constants.TRANSPORT_FEE}
                      placeholder={constants.TRANSPORT_FEE}
                      validationObj={validations.transport_fee}
                      onChange={(e) =>
                        setData({
                          ...data,
                          transport_fee: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.transport_fee}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.MISCELLANEOUS}
                      placeholder={constants.MISCELLANEOUS}
                      validationObj={validations.ssp}
                      onChange={(e) =>
                        setData({
                          ...data,
                          ssp: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.ssp}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper style={{ marginRight: '8px' }}>
                    <InputComponent
                      lable={constants.TRAINING_MARGIN}
                      placeholder={constants.TRAINING_MARGIN}
                      validationObj={validations.training_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          training_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.training_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.BH_MARGIN}
                      placeholder={constants.BH_MARGIN}
                      validationObj={validations.bh_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          bh_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.bh_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper style={{ marginRight: '8px' }}>
                    <InputComponent
                      lable={constants.SP_MARGIN}
                      placeholder={constants.SP_MARGIN}
                      validationObj={validations.nsp_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          nsp_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.nsp_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.INTERNAL_MARGIN}
                      placeholder={constants.INTERNAL_MARGIN}
                      validationObj={validations.internal_standard_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          internal_standard_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.internal_standard_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper style={{ marginRight: '8px' }}>
                    <InputComponent
                      lable={constants.INTERNAL_OVERTIME_MARGIN}
                      placeholder={constants.INTERNAL_OVERTIME_MARGIN}
                      validationObj={validations.internal_overtime_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          internal_overtime_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.internal_overtime_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.INTERNAL_PERMANENT_MARGIN}
                      placeholder={constants.INTERNAL_PERMANENT_MARGIN}
                      validationObj={validations.internal_permanent_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          internal_permanent_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.internal_permanent_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                <Flex>
                  <StyledInputWrapper>
                    <InputComponent
                      lable={constants.SUSPENSION_MARGIN}
                      placeholder={constants.SUSPENSION_MARGIN}
                      validationObj={validations.suspension_margin}
                      onChange={(e) =>
                        setData({
                          ...data,
                          suspension_margin: e ? allowTwoDecimalNumbers(e).toString() : ''
                        })
                      }
                      refresh={refresh}
                      value={data.suspension_margin}
                      type="number"
                      min="0"
                    />
                  </StyledInputWrapper>
                </Flex>
                {(userDetails.user_type_id === USER_TYPE.SUPER_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) && (
                  <SiteMargins associationId={selectedRow} />
                )}
              </Box>
            }
            negative={
              <Button
                onClick={() => onModalClose()}
                mr={3}
                _hover={{ bg: 'main.secondary' }}
                color="main.black">
                {constants.ASSOCIATION.CANCEL_BUTTON_TEXT}
              </Button>
            }
            positive={
              <Button
                onClick={() => saveAssociation()}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}>
                {edit
                  ? constants.ASSOCIATION.EDIT_UPDATE_BUTTON_TEXT
                  : constants.ASSOCIATION.SAVE_BUTTON_TEXT}
              </Button>
            }
          />

          <SiteSelectionModal
            tableRefreash={() => setReload((prev) => !prev)}
            isOpen={isSiteModalOpen}
            onClose={() => setSiteModalOpen(false)}
            onSetMargin={handleSetMargin}
            associationId={selectedRow}
          />

          <ServerSideDatatable
            pageSize={5}
            initialSortKey={
              userDetails && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN
                ? 'client_name'
                : 'agency_name'
            }
            column={column}
            onPaginate={
              userDetails.user_type_id === USER_TYPE.SUPER_ADMIN
                ? getAssociations
                : userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN
                ? getAgencyAssociations
                : getClientAssociations
            }
            pagination
            pages={[5, 10, 15]}
            dataKey="agency_association_list"
            refresh={reload}
            afterPaginateData={() => setIsPageLoader(false)}
            id={
              userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN
                ? userDetails.agency_id
                : userDetails.client_id
            }
          />
        </Card>
      </StyledAgencyContainer>{' '}
    </>
  );
};

Associations.propTypes = {};

export default Associations;
