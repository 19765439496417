import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

export const uploadWorkersPerformance = async (
  clientId,
  siteId,
  startDate,
  endDate,
  week,
  workerPerformance
) => {
  // Constructing query parameters based on provided values
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (siteId) params.append('site_id', siteId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);
  if (week) params.append('week', week);

  // Constructing the complete URL with query parameters
  const url = `${API_ENDPOINT.WORKER_PERFORMANCE}?${params.toString()}`;

  const result = await axiosInstance.post(url, await toFormData({ workerPerformance }));
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const deleteWorkerPerformance = async (clientId, siteId, startDate, endDate) => {
  // Constructing query parameters based on provided values
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (siteId) params.append('site_id', siteId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  // Constructing the complete URL with query parameters
  const url = `${API_ENDPOINT.WORKER_PERFORMANCE}`;

  // Creating the data payload
  const bodyPayload = {
    client_id: clientId,
    site_id: siteId,
    start_date: startDate,
    end_date: endDate
  };

  try {
    // Making the DELETE request
    const result = await axiosInstance.delete(url, {
      data: bodyPayload // Include the data in the body as well
    });

    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  } catch (error) {
    return prepareErrorResponse({ error });
  }
};

export const downloadWorkerPerformance = async (clientId, siteId, startDate, endDate, week) => {
  let queryString = `client_id=${clientId}`;
  if (siteId) {
    queryString += `&site_id=${siteId}`;
  }
  if (startDate) {
    queryString += `&start_date=${startDate}`;
  }
  if (endDate) {
    queryString += `&end_date=${endDate}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.WORKER_PERFORMANCE}?${queryString}&week=${week}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const downloadPerformanceSample = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_WORKER_PERFORMANCE_DOWNLOAD_SAMPLE}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
