/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-named-as-default-member */
import {
  Box,
  Button,
  Flex,
  Image,
  Radio,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card';
import StyledInputWrapper from '../Forms/Forms.styled';
import InputComponent from '../Input';
import StyledMessagingViewContainer, {
  StyledMessagingBodyContainer,
  StyledMessagingFormContainer,
  StyledMobileContainer,
  StyledMobileHeader,
  StyledMobileHeaderTitle,
  StyledMobileScreen,
  StyleMessageContainer,
  StyleMessageUserLogo,
  StyleMobile
} from './MessagingView.styled';
import MessageTypeDropdown from '../Forms/MessageTypeDropDown';
import Label from '../Label';
import constants, { REDUX_SELECTOR, USER_TYPE } from '../../constants';
import TemplateDropdown from '../Forms/TemplateDropdown';
import ModalComponent from '../Modal';
import ProfileImageUploader from '../ProfileImageUploader';
import { addWorkerDocs } from '../../redux/action/worker.action';
import ShiftDropdown from '../Forms/ShiftDropdown';
import DepartmentDropdown from '../Forms/DepartmentDropdown';
import JobDropdown from '../Forms/JobDropdown';
import WorkerDropdown from '../Forms/WorkerDropdown';
import { colors } from '../../theme';
import ArrowLeftWithDashIcon from '../../icons/ArrowLeftWithDashIcon';
import AlertModalOverlay from '../AlertModalComponent';
import WorkerNationalityDropdown from '../Forms/WorkerNationalityDropdown';
import CompanyDropdown from '../Forms/CompanyDropdown';
import SiteDropdownWithDefault from '../Forms/SiteDropdownWithDefault';
import WorkerTypeDropdown from '../Forms/WorkerTypeDropdown';
import DeleteIcon from '../../assets/images/delete-icon.png';

const INITIAL_MESSAGE = {
  text: '',
  media: '',
  link: ''
};

const MessagingView = function ({
  goBackOnClick,
  isComposeType,
  isNewTemplate,
  templateData,
  onMessageTypeChange,
  onTemplateSelect,
  onSaveBtnClick,
  onInputChange,
  onBodyInputChange,
  onAddNewBody,
  setSelectedTemplate,
  selectedTemplate,
  shift,
  setShift,
  department,
  setDepartment,
  role,
  setRole,
  siteId,
  worker,
  setWorker,
  nationality,
  setNationality,
  removeBodyItem,
  userType,
  validation,
  refresh,
  resetFilters,
  getWorkerType,
  onTranslateClicked,
  translateclient,
  translateworkerType,
  edit,
  setedit,
  setTemplateData,
  loader,
  onTranslateAllBtnClicked,
  onDeleteBtnClicked
}) {
  const [messages, setMessages] = useState([]);
  const [messageItemIndex, setMessageItemIndex] = useState(null);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [sendTo, setSendTo] = useState('1');
  const [isSaveUpdateAlert, setIsSaveUpdateAlert] = useState(false);
  const [deleteAlert, setIsDeleteAlert] = useState(false);
  const [isLargerThan786] = useMediaQuery('(min-height: 786px)');
  const [client, setClientData] = useState(null);
  const [site, setSite] = useState(null);
  const { user_details } = useSelector((state) => state.authentication);
  const [workerType, setWorkerType] = useState({ label: 'All Workers', value: '' });
  const [commentToggle, setcommentToggle] = useState(false);
  useEffect(() => {
    if (!isImageUploadModalOpen) {
      setMessageItemIndex(null);
    }
  }, [isImageUploadModalOpen]);
  useEffect(() => {
    if (messageItemIndex !== null) {
      setIsImageUploadModalOpen(true);
    }
  }, [messageItemIndex]);

  useEffect(() => {
    if (isComposeType) {
      setMessages((previosSate) => {
        return [{ ...INITIAL_MESSAGE }];
      });
    } else {
      setMessages((previosSate) => {
        return [{ ...INITIAL_MESSAGE }];
      });
    }
  }, []);

  useEffect(() => {
    setSite(null);
    resetFilters();
  }, [client]);
  useEffect(() => {
    resetFilters();
  }, [site]);

  useEffect(() => {
    if (templateData.type.value === 'GENERAL') {
      setSendTo('2');
    } else {
      setSendTo('1');
    }
  }, [templateData.type.value]);

  const onSelectSendToType = (e) => {
    setSendTo(e);
    setShift([]);
    setDepartment([]);
    setRole([]);
    setWorker([]);
    setNationality([]);
  };

  const openImageUploadModal = (index) => {
    setMessageItemIndex(index);
  };
  const onMediaUpload = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];
    const result = await addWorkerDocs({
      media: file
    });
    if (result.ok && result.data.documents.media.url) {
      onBodyInputChange('media', messageItemIndex, result.data.documents.media.url);
    } else {
      console.log('Error message');
    }
    setIsImageUploadModalOpen(false);
  };

  const saveBtnClickHandler = (operation) => {
    switch (operation) {
      case 'save':
        if (!templateData.id) {
          onSaveBtnClick('create');
        } else if (isNewTemplate) {
          onSaveBtnClick('create');
        } else {
          setIsSaveUpdateAlert(true);
        }
        break;
      case 'send':
        onSaveBtnClick('send', sendTo, site, client, commentToggle);
        break;
      default:
        break;
    }
  };

  const onDeleteOpenAlertPopup = () => {
    setIsDeleteAlert(true);
  };

  const setWorkerTypeDropdown = (e) => {
    setWorkerType(e);
    getWorkerType(e);
  };

  const dispatch = useDispatch();
  const onTranslateClick = (operation) => {
    let result;
    if (operation === 'TRANSLATE_ALL' || operation === 'DESIGN_TRANSLATE_ALL') {
      result = {
        templateData: templateData,
        translateAll: true,
        client: client
      };
    } else if (operation === 'Edit TRANSLATE' || operation === 'DESIGN_EDIT_TRANSLATE_ALL') {
      result = {
        templateData: templateData,
        editTranslate: true,
        client: client
      };
    } else {
      result = isComposeType
        ? {
            templateData: templateData,
            selectedTemplate: selectedTemplate,
            refresh: refresh,
            validation: validation,
            sendTo: sendTo,
            worker: worker,
            workerType: workerType,
            role: role,
            department: department,
            shift: shift,
            nationality: nationality,
            isComposeType: true,
            selectedClient: client,
            client: translateclient,
            selectedSite: site,
            WorkerType: translateworkerType,
            commentToggle: commentToggle,
            comment_restricted: client?.comment_restricted
          }
        : {
            templateData: templateData,
            selectedTemplate: selectedTemplate,
            refresh: refresh,
            validation: validation,
            // sendTo: sendTo,
            worker: worker,
            workerType: workerType,
            role: role,
            department: department,
            shift: shift,
            nationality: nationality,
            isComposeType: false
          };
    }
    const savedata = result;
    switch (operation) {
      case 'save':
        if (!templateData.id) {
          savedata.state = 'create';
          onTranslateClicked('create');
          dispatch({
            type: REDUX_SELECTOR.TRANSLATE_DATA,
            payload: savedata
          });
        } else if (isNewTemplate) {
          savedata.state = 'create';
          onTranslateClicked('create');
          dispatch({
            type: REDUX_SELECTOR.TRANSLATE_DATA,
            payload: savedata
          });
        } else {
          savedata.state = 'update';
          onTranslateClicked('update');
          dispatch({
            type: REDUX_SELECTOR.TRANSLATE_DATA,
            payload: savedata
          });
        }
        break;
      case 'send': {
        savedata.state = 'send';
        onTranslateClicked(operation, sendTo);
        dispatch({
          type: REDUX_SELECTOR.TRANSLATE_DATA,
          payload: savedata
        });
        break;
      }
      case 'TRANSLATE_ALL': {
        onTranslateAllBtnClicked();
        dispatch({
          type: REDUX_SELECTOR.TRANSLATE_DATA,
          payload: savedata
        });
        break;
      }
      case 'DESIGN_TRANSLATE_ALL': {
        onTranslateAllBtnClicked(true);
        dispatch({
          type: REDUX_SELECTOR.TRANSLATE_DATA,
          payload: savedata
        });
        break;
      }
      case 'DESIGN_EDIT_TRANSLATE_ALL': {
        onTranslateAllBtnClicked(true);
        dispatch({
          type: REDUX_SELECTOR.TRANSLATE_DATA,
          payload: savedata
        });
        break;
      }

      case 'Edit TRANSLATE': {
        onTranslateAllBtnClicked();
        dispatch({
          type: REDUX_SELECTOR.TRANSLATE_DATA,
          payload: savedata
        });
        break;
      }
      default:
        break;
    }
  };
  const INITIAL_MESSAGE_DATA = {
    name: '',
    title: '',
    type: { value: 'GENERAL', label: 'General' },
    from: '',
    languages: { label: 'English', value: 'en' },
    body: [
      {
        text: '',
        media: '',
        link: '',
        isValid: true,
        isValidUrl: true
      }
    ]
  };
  return (
    <StyledMessagingViewContainer>
      <AlertModalOverlay
        isOpen={isSaveUpdateAlert}
        onClose={() => setIsSaveUpdateAlert(false)}
        title="Choose Action"
        body={
          templateData.is_master_template
            ? `If you are updating this master template, be sure you hit "translate all" to update all translations as well.`
            : ''
        }
        negative={
          <>
            {templateData.is_master_template && (
              <Button
                onClick={() => {
                  setIsSaveUpdateAlert(false);
                }}
                bg="main.secondary"
                mr={3}
                _hover={{ bg: 'main.secondary' }}
                color="main.black">
                Cancel
              </Button>
            )}
            <Button
              onClick={() => {
                onSaveBtnClick('update');
                setIsSaveUpdateAlert(false);
              }}
              mr={3}
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}>
              Update Existing
            </Button>
          </>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              onSaveBtnClick('create');
              setIsSaveUpdateAlert(false);
            }}>
            Create New
          </Button>
        }
      />
      <AlertModalOverlay
        isOpen={deleteAlert}
        onClose={() => setIsDeleteAlert(false)}
        title="Choose Action"
        body="Are you sure you want to Delete this template ?"
        negative={
          <Button
            onClick={() => {
              setIsDeleteAlert(false);
            }}
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black">
            No
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              onDeleteBtnClicked();
              setIsDeleteAlert(false);
            }}>
            Yes
          </Button>
        }
      />
      <ModalComponent
        isOpen={isImageUploadModalOpen}
        onClose={() => setIsImageUploadModalOpen(false)}
        modalTitle="Upload Media"
        modalContent={
          <Flex mb="16px" justifyContent="center">
            <ProfileImageUploader
              altLabel=""
              src={
                templateData.body[messageItemIndex] && templateData.body[messageItemIndex].media
                  ? templateData.body[messageItemIndex].media
                  : ''
              }
              onChange={onMediaUpload}
              width={160}
            />
          </Flex>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              onBodyInputChange('media', messageItemIndex, '');
            }}>
            Remove
          </Button>
        }
      />
      <Card>
        <Flex alignItems="center">
          <Text fontSize="2xl" color="main.primary">
            <b>
              {isComposeType
                ? edit
                  ? constants.MESSAGING.UPDATE_MESSAGE_TITLE
                  : constants.MESSAGING.COMPOSE_MESSAGE_TITLE
                : constants.MESSAGING.MESSAGE_DESIGNS_TITLE}
            </b>
          </Text>
          <Spacer />
          {edit ? (
            <Button
              mt={
                user_details.user_type_id === USER_TYPE.SITE_ADMIN && isComposeType
                  ? '24px'
                  : user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
                  ? '0px'
                  : '7px'
              }
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                setTemplateData({
                  ...INITIAL_MESSAGE_DATA,
                  body: [...INITIAL_MESSAGE_DATA.body]
                });
                goBackOnClick(0);
                setedit(false);
              }}>
              Go Back
            </Button>
          ) : (
            <Flex alignItems="center">
              {userType === USER_TYPE.AGENCY_ADMIN && isComposeType && (
                <>
                  <Box width="180px" mr="8px">
                    <CompanyDropdown
                      company={client}
                      setCompany={setClientData}
                      disabled={false}
                      setDefaultValue
                    />
                  </Box>
                  <Box width="180px" mr="8px">
                    <SiteDropdownWithDefault
                      label="Site"
                      site={site}
                      setSite={setSite}
                      disabled={false}
                      multiple={false}
                      setDefaultValue
                      clientId={(client && client.value) || null}
                    />
                  </Box>
                </>
              )}
              {(user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                isComposeType && (
                  <>
                    <Box w="180px" mr="15px">
                      <SiteDropdownWithDefault
                        label="Site"
                        site={site}
                        setSite={setSite}
                        disabled={false}
                        multiple={false}
                        setDefaultValue
                        clientId={(client && client.value) || null}
                      />
                    </Box>
                    <Box w="180px" mr="15px" h="83.5px">
                      <WorkerTypeDropdown
                        workerType={workerType}
                        setWorkerTypeDropdown={setWorkerTypeDropdown}
                        disabled={false}
                        multiple={false}
                        setDefaultValue
                      />
                    </Box>
                  </>
                )}
              {userType === USER_TYPE.AGENCY_REGION_ADMIN && isComposeType && (
                <Box width="180px" mr="8px">
                  <SiteDropdownWithDefault
                    label="Site"
                    site={site}
                    setSite={setSite}
                    disabled={false}
                    multiple={false}
                    setDefaultValue
                    clientId={(client && client.value) || null}
                  />
                </Box>
              )}
              <Flex alignItems="center" justifyContent="center">
                {user_details.user_type_id === USER_TYPE.SITE_ADMIN && isComposeType && (
                  <Box w="250px" mr="15px">
                    <WorkerTypeDropdown
                      workerType={workerType}
                      setWorkerTypeDropdown={setWorkerTypeDropdown}
                      disabled={false}
                      multiple={false}
                      setDefaultValue
                    />
                  </Box>
                )}

                <Button
                  mt={
                    user_details.user_type_id === USER_TYPE.SITE_ADMIN && isComposeType
                      ? '24px'
                      : user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
                      ? '0px'
                      : '7px'
                  }
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => goBackOnClick(0)}>
                  Go Back
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Card>

      <StyledMessagingBodyContainer>
        <StyledMessagingFormContainer>
          <Card messagingView style={{ width: '100%' }}>
            <Box p="10px">
              {!edit ? (
                <>
                  <Flex>
                    <StyledInputWrapper style={{ marginRight: '8px' }}>
                      <MessageTypeDropdown
                        messageType={templateData.type}
                        refresh
                        setMessageType={(e) => {
                          onMessageTypeChange({ ...e });
                        }}
                        label="Choose Message Type"
                        labelFontSize="sm"
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper>
                      <TemplateDropdown
                        messageType={templateData.type.value}
                        refresh
                        setTemplate={(e) => setSelectedTemplate({ ...e })}
                        label="Choose Template"
                        labelFontSize="sm"
                        template={selectedTemplate}
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        lable="Template Name"
                        placeholder="Template Name"
                        validationObj={validation.name}
                        templatevalidation={templateData?.name}
                        value={templateData.name}
                        onChange={(e) => onInputChange('name', e)}
                        refresh={refresh}
                        isRequired
                        maxLength="150"
                        labelFontSize="sm"
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        lable="From"
                        placeholder="From"
                        templatevalidation={templateData?.from}
                        validationObj={validation.from}
                        isRequired
                        maxLength="150"
                        value={templateData.from}
                        refresh={refresh}
                        onChange={(e) => onInputChange('from', e)}
                        labelFontSize="sm"
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        lable="Message Title"
                        placeholder="Message Title"
                        templatevalidation={templateData?.title}
                        validationObj={validation.title}
                        isRequired
                        value={templateData.title}
                        refresh={refresh}
                        onChange={(e) => onInputChange('title', e)}
                        maxLength="150"
                        labelFontSize="sm"
                      />
                    </StyledInputWrapper>
                  </Flex>

                  {isComposeType ? (
                    <>
                      <Box>
                        <Label label="Send to" fontSize="sm" color="main.primary" />
                      </Box>
                      <Flex style={{ alignItems: 'center' }}>
                        <StyledInputWrapper>
                          <Flex>
                            <RadioGroup value={sendTo} ml={2} onChange={onSelectSendToType}>
                              <Stack direction="row">
                                {templateData.type.value !== 'GENERAL' && (
                                  <Radio value="1">Individual(s)</Radio>
                                )}
                                <Radio value="2">Group(s)</Radio>
                                <Radio value="3">All Workers</Radio>
                              </Stack>
                            </RadioGroup>
                          </Flex>
                        </StyledInputWrapper>
                      </Flex>
                      {sendTo === '1' ? (
                        <Flex>
                          <StyledInputWrapper>
                            <WorkerDropdown
                              worker={worker}
                              setWorker={setWorker}
                              multiple
                              validationObj={{ isvalid: true }}
                              siteId={(site && site.value) || user_details.site_id || null}
                              clientId={(client && client.value) || user_details.client_id || null}
                              workerType={workerType.value}
                            />
                          </StyledInputWrapper>
                        </Flex>
                      ) : sendTo === '2' ? (
                        <Flex flexDirection="column">
                          <StyledInputWrapper style={{ marginRight: '8px' }}>
                            <JobDropdown
                              roleType={role}
                              setRoleType={setRole}
                              multiple
                              validationObj={{ isvalid: true }}
                              siteId={(site && site.value) || user_details.site_id || null}
                              type="messaging"
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper style={{ marginRight: '8px' }}>
                            <DepartmentDropdown
                              department={department}
                              setDepartment={setDepartment}
                              multiple
                              validationObj={{ isvalid: true }}
                              siteId={(site && site.value) || user_details.site_id || null}
                              clientId={(client && client.value) || user_details.client_id || null}
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper>
                            <ShiftDropdown
                              shift={shift}
                              setShift={setShift}
                              multiple
                              validationObj={{ isvalid: true }}
                              siteId={(site && site.value) || user_details.site_id || null}
                              clientId={(client && client.value) || user_details.client_id || null}
                            />
                          </StyledInputWrapper>
                          <StyledInputWrapper>
                            <WorkerNationalityDropdown
                              nationality={nationality}
                              setNationality={setNationality}
                              multiple
                              validationObj={{ isvalid: true }}
                              siteId={(site && site.value) || user_details.site_id || null}
                              clientId={(client && client.value) || user_details.client_id || null}
                            />
                          </StyledInputWrapper>
                        </Flex>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : (
                <Flex>
                  <StyledInputWrapper>
                    <InputComponent
                      lable="From"
                      placeholder="From"
                      validationObj={validation.from}
                      isRequired
                      maxLength="150"
                      value={templateData.from}
                      refresh={refresh}
                      onChange={(e) => onInputChange('from', e)}
                      labelFontSize="sm"
                    />
                  </StyledInputWrapper>
                </Flex>
              )}
              {templateData.body.map((message, index) => {
                return (
                  <fieldset className="custom-fieldset" key={index}>
                    <legend className="custom-legend">Message Body</legend>
                    {index ? (
                      <Flex flexDirection="row-reverse">
                        <Button
                          bg="main.semiPrimary"
                          color="main.white"
                          _hover={{ bg: 'main.primary' }}
                          _active={{
                            bg: 'main.semiPrimary',
                            borderColor: 'main.primary'
                          }}
                          onClick={() => removeBodyItem(index)}>
                          Remove
                        </Button>
                      </Flex>
                    ) : null}
                    <Flex style={{ alignItems: 'center' }}>
                      <StyledInputWrapper>
                        <p
                          label="Body Text*"
                          fontSize="sm"
                          style={{ fontSize: '0.875rem' }}
                          className={`input-label ${!message.isValid ? 'input-label-error' : ''}`}>
                          Body Text*
                        </p>
                        <textarea
                          className={message.isValid ? 'custom-input' : 'custom-input input-error'}
                          rows="4"
                          value={message.text}
                          onChange={(e) => onBodyInputChange('text', index, e.target.value)}
                        />
                        <p
                          className="input-error-msg"
                          style={{ visibility: message.isValid ? 'hidden' : 'visible' }}>
                          Body Text is required
                        </p>
                      </StyledInputWrapper>
                    </Flex>
                    <Flex>
                      <StyledInputWrapper>
                        <InputComponent
                          lable="Add Media"
                          placeholder="Add Media"
                          validationObj={{ isvalid: true }}
                          labelFontSize="sm"
                          value={message.media}
                          onClick={() => openImageUploadModal(index)}
                        />
                      </StyledInputWrapper>
                    </Flex>
                    <Flex>
                      <StyledInputWrapper>
                        <InputComponent
                          lable="Add Link"
                          placeholder="Add Link"
                          validationObj={{
                            required: false,
                            isvalid: message.isValidUrl,
                            errorMessage: '*Please Enter Valid URL'
                          }}
                          labelFontSize="sm"
                          value={message.link}
                          refresh={refresh}
                          onChange={(e) => onBodyInputChange('link', index, e)}
                        />
                      </StyledInputWrapper>
                    </Flex>
                  </fieldset>
                );
              })}

              {edit ? (
                <Flex justifyContent="flex-end">
                  <Button
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black"
                    onClick={() => {
                      setTemplateData({
                        ...INITIAL_MESSAGE_DATA,
                        body: [...INITIAL_MESSAGE_DATA.body]
                      });
                      goBackOnClick(0);
                      setedit(false);
                    }}>
                    {constants.MESSAGING.CANCEL_BTN_LABEL}
                  </Button>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    mr="10px"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    disabled={loader}
                    onClick={() => onTranslateClick('Edit TRANSLATE')}>
                    Edit Translate
                  </Button>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    mr="10px"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    disabled={loader}
                    onClick={() => onTranslateClick('TRANSLATE_ALL')}>
                    {constants.MESSAGING.TRANSLATE_ALL_LABEL}
                  </Button>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => onSaveBtnClick()}>
                    {constants.MESSAGING.UPDATE_BTN_LABEL}
                  </Button>
                </Flex>
              ) : (
                <Flex justifyContent="flex-end">
                  {templateData.type && templateData.type.value === 'TRAINING' && (
                    <Button
                      bg="main.semiPrimary"
                      color="main.white"
                      _hover={{ bg: 'main.primary' }}
                      _active={{
                        bg: 'main.semiPrimary',
                        borderColor: 'main.primary'
                      }}
                      onClick={onAddNewBody}
                      mr="10px">
                      {constants.MESSAGING.NEW_PAGE_BUTTON_TEXT}
                    </Button>
                  )}
                  {isComposeType ? (
                    <>
                      {!client?.comment_restricted && (
                        <Flex justifyContent="center" alignItems="center" mr="10px">
                          <Text color="main.primary" mr="10px" fontSize="18px">
                            {' '}
                            Is Comment Allowed?
                          </Text>
                          <Switch
                            isChecked={commentToggle}
                            onChange={() => {
                              setcommentToggle(!commentToggle);
                            }}
                          />
                        </Flex>
                      )}

                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        mr="10px"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={loader}
                        onClick={() => onTranslateClick('send')}>
                        {constants.MESSAGING.TRANSLATE_BTN_LABEL}
                      </Button>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={loader}
                        onClick={() => saveBtnClickHandler('send')}>
                        {loader ? (
                          <Spinner size="sm" color="main.white" />
                        ) : (
                          constants.MESSAGING.SEND_BTN_LABEL
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Tooltip label={selectedTemplate ? 'Delete Template' : ''}>
                        <Button
                          bg="main.semiPrimary"
                          color="main.white"
                          mr="10px"
                          _hover={{ bg: 'main.primary' }}
                          _active={{
                            bg: 'main.semiPrimary',
                            borderColor: 'main.primary'
                          }}
                          disabled={!selectedTemplate}
                          onClick={() => onDeleteOpenAlertPopup()}>
                          <Image src={DeleteIcon} width="20px" height={5} />
                        </Button>
                      </Tooltip>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        mr="10px"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={loader || !selectedTemplate || !templateData.is_master_template}
                        onClick={() => onTranslateClick('DESIGN_EDIT_TRANSLATE_ALL')}>
                        Edit Translate
                      </Button>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        mr="10px"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={loader || !selectedTemplate}
                        onClick={() => onTranslateClick('DESIGN_TRANSLATE_ALL')}>
                        {constants.MESSAGING.TRANSLATE_ALL_LABEL}
                      </Button>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        mr="10px"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        disabled={!selectedTemplate}
                        onClick={() => onTranslateClick('save')}>
                        {constants.MESSAGING.TRANSLATE_BTN_LABEL}
                      </Button>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        onClick={() => saveBtnClickHandler('save')}>
                        {constants.MESSAGING.SAVE_BTN_LABEL}
                      </Button>
                    </>
                  )}
                </Flex>
              )}
            </Box>
          </Card>
        </StyledMessagingFormContainer>
        <StyledMobileContainer>
          <StyleMobile
            style={{
              height: isLargerThan786 ? '500px' : '420px',
              width: isLargerThan786 ? '295px' : '260px'
            }}>
            <StyledMobileHeader>
              <ArrowLeftWithDashIcon />
              <StyledMobileHeaderTitle style={{ marginLeft: isLargerThan786 ? '70px' : '55px' }}>
                Messages
              </StyledMobileHeaderTitle>
            </StyledMobileHeader>
            <StyledMobileScreen style={{ height: isLargerThan786 ? '420px' : '376px' }}>
              <StyleMessageContainer>
                <Flex style={{ width: '100%' }}>
                  <StyleMessageUserLogo
                    style={{
                      height: isLargerThan786 ? '40px' : '35px',
                      width: isLargerThan786 ? '40px' : '35px',
                      fontSize: isLargerThan786 ? '2rem' : '1.5rem',
                      paddingTop: '3px',
                      paddingRight: '1px'
                    }}>
                    {userType === USER_TYPE.AGENCY_ADMIN ||
                    userType === USER_TYPE.AGENCY_REGION_ADMIN ||
                    userType === USER_TYPE.AGENCY_SITE_ADMIN
                      ? 'A'
                      : 'C'}
                  </StyleMessageUserLogo>
                  <Box fontSize="xs" width="130px">
                    <Text
                      color={colors.main.gray}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      <b>{templateData.from || ''}</b>
                    </Text>
                    <Text
                      mb={2}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      <b>{templateData.title}</b>
                    </Text>
                  </Box>

                  <Spacer />
                  <Text color={colors.main.gray}>
                    <b>Today</b>
                  </Text>
                </Flex>
                <Box mt={4} mb={2}>
                  {templateData.body.map((item) => (
                    <>
                      <pre
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                          marginBottom: '4px'
                        }}>
                        {item.text}
                      </pre>
                      {item.link && item.link.length > 0 ? (
                        <Text mb={2}>
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <u>Link</u>
                          </a>
                        </Text>
                      ) : null}
                      {item.media && item.media.length > 0 ? (
                        <Box>
                          <img alt="Message Image" src={item.media} />
                        </Box>
                      ) : null}
                    </>
                  ))}
                </Box>
              </StyleMessageContainer>
            </StyledMobileScreen>
            {/* <StyledMobileFooter>
              <EnvelopeIcon />
              <UserProfileCardIcon />
              <MedalIcon />
            </StyledMobileFooter> */}
          </StyleMobile>
        </StyledMobileContainer>
      </StyledMessagingBodyContainer>
    </StyledMessagingViewContainer>
  );
};

export default MessagingView;
