/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import constants, {
  ADD_REGION_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION
} from '../../constants';
import { addRegion, getRegions, updateRegion } from '../../redux/action/region.action';
import {
  getHeaderData,
  getRatingData,
  getLeaverAnalysisData,
  getActivityTopCardData,
  // getWorkforceTopCardDemographicsData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  // getLeaverTopCardShiftUti,
  getActivityBottomCardShiftUti,
  // getActivityBottomCardLostShift,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  // getWorkforceDemo,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  // getLeaverShiftUtl,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
} from '../../redux/action/regionDashboard.action';
import ModalComponent from '../../components/Modal';
import InputComponent from '../../components/Input';
import { deepClone, validateForm, isDashboardBottomCardVisible } from '../../utils/helper';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import Card from '../../components/Card';
import StyledRegionContainer from './Region.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import CustomCarousel from '../../components/CustomCarousel';
import DashboardRatingCard from '../../components/DashboardRatingCard';

const Region = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.REGION;
  }, []);

  const toast = useToast();
  const permission = getModulePermissions(PATH.REGION.CODE);
  const [regionData, setRegionData] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_start_date, selected_end_date, is_restricted } = useSelector(
    (state) => state.agency
  );

  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_REGION_VALIDATIONS));
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);

  const [data, setData] = useState({
    name: ''
  });
  const [isPageLoader, setIsPageLoader] = useState(false);

  const getRegion = async () => {
    if (selected_client_id || userData.client_id) {
      setIsPageLoader(true);
      const result = await getRegions(selected_client_id || userData.client_id);
      setIsPageLoader(false);
      if (result && result.ok) {
        setRegionData(result.data?.region_details);
        setRegionIdForDashboard(result.data?.region_details[0].id);
        getChartData(result.data?.region_details[0].id, selected_start_date, selected_end_date);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  useEffect(async () => {
    if (user_details.user_type_id !== USER_TYPE.AGENCY_ADMIN) getRegion();
  }, []);

  const onModalClose = () => {
    setModalOpen(false);
    setEdit(false);
    setRefresh(!refresh);
    setData({
      name: ''
    });
    setValidations(deepClone(ADD_REGION_VALIDATIONS));
  };

  const saveRegion = async () => {
    const [validationObject, isFormValid] = validateForm(data, validations);
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        result = await updateRegion(id, { ...data, client_id: userData.client_id });
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setEdit(false);
          getRegion();
          onModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        result = await addRegion({ ...data, client_id: userData.client_id });
        if (result && result.ok) {
          setId(result.data?.site_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          onModalClose();
          setEdit(false);
          getRegion();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const editData = (rowId, row) => {
    setEdit(true);
    setData({
      name: row.name
    });
    setModalOpen(true);
    setId(rowId);
  };

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'name',
      sort: false
    }
  ]);

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(cell, row)}>
                {constants.REGION.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ]);
    }
  }, [permission]);

  useEffect(() => {
    if (user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) getRegion();
    setReload(!reload);
  }, [selected_client_id]);

  useEffect(() => {
    if (user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) getRegion();
    setReload(!reload);
  }, [selected_client_id]);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(() => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
    if (
      regionData &&
      regionData.length > 0 &&
      regionData[currentIndex] &&
      regionData[currentIndex].id
    ) {
      resetData();
      setRegionIdForDashboard(regionData[currentIndex].id);
      getChartData(regionData[currentIndex].id, selected_start_date, selected_end_date);
    }
  }, [selected_start_date, selected_end_date]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [headerData, setHeaderData] = useState(null);
  const [ratingData, setratingData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [activityTopCardData, setActivityTopCardData] = useState(null);
  // const [workforceTopCardDemographics, setWorkforceTopCardDemographics] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  // const [activityBottomCardLostShift, setActivityBottomCardLostShift] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  // const [workforceDemo, setWorkforceDemo] = useState(null);
  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  // const [leaverShiftUtl, setLeaverShiftUtl] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  const [regionIdForDashboard, setRegionIdForDashboard] = useState(null);

  const resetData = () => {
    setAgencyRatingData(null);
    setClientRatingData(null);
    setSiteRatingData(null);
    setActivityTopCardData(null);
    // setWorkforceTopCardDemographics(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardLos(null);
    setLeaverTopCardCounts(null);
    // setLeaverTopCardShiftUti(null);
    setActivityBottomCardShiftUtil(null);
    // setActivityBottomCardLostShift(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    // setWorkforceDemo(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    // setLeaverShiftUtl(null);
    setLeaverLOS(null);
  };

  const getHeaderDataFunction = async (regionId, startDate, endDate) => {
    const header = await getHeaderData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };

  const getRatingDataFunction = async (regionId, startDate, endDate) => {
    const rating = await getRatingData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (rating && rating.ok) {
      setratingData(rating.data.result);
    }
  };

  const getSiteRatingDataFunction = async (regionId) => {
    const rating = await getSiteRatingData(userData.client_id || selected_client_id, regionId);
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(
      userData.client_id || selected_client_id,
      user_details.agency_id
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(userData.client_id || selected_client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getLeaverAnalysisDataFunction = async (regionId, startDate, endDate) => {
    const leaversAnalysis = await getLeaverAnalysisData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaversAnalysis && leaversAnalysis.ok) {
      setLeaversData(leaversAnalysis.data);
    }
  };

  const getActivityTopCardDataFunction = async (regionId, startDate, endDate) => {
    const activityTopCard = await getActivityTopCardData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  // const getWorkforceTopCardDemographicsDataFunction = async (regionId, startDate, endDate) => {
  //   const WorkforceTopCard = await getWorkforceTopCardDemographicsData(
  //     selected_client_id || userData.client_id,
  //     regionId,
  //     startDate,
  //     endDate
  //   );
  //   if (WorkforceTopCard && WorkforceTopCard.ok) {
  //     setWorkforceTopCardDemographics(WorkforceTopCard.data.result);
  //   }
  // };

  const getWorkforceTopCardLOSDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async (regionId, startDate, endDate) => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async (regionId, startDate, endDate) => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async (regionId, startDate, endDate) => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardPoolUtiFunction = async (regionId, startDate, endDate) => {
    const leaverTopCardData = await getLeaverTopCardPoolUti(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaversTopCardPoolUti(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  // const getActivityBottomCardLostShiftFunction = async (regionId, startDate, endDate) => {
  //   const bottomDeckData = await getActivityBottomCardLostShift(
  //     selected_client_id || userData.client_id,
  //     regionId,
  //     startDate,
  //     endDate
  //   );
  //   if (bottomDeckData && bottomDeckData.ok) {
  //     setActivityBottomCardLostShift(bottomDeckData?.data?.result?.rows);
  //   }
  // };

  const getActivityBottomCardHeadCountFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardSpend(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  // const getWorkforceDemoFunction = async (regionId, startDate, endDate) => {
  //   const bottomDeckData = await getWorkforceDemo(
  //     selected_client_id || userData.client_id,
  //     regionId,
  //     startDate,
  //     endDate
  //   );
  //   if (bottomDeckData && bottomDeckData.ok) {
  //     setWorkforceDemo(bottomDeckData?.data?.result?.rows);
  //   }
  // };

  const getWorkforceShiftUtiFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getWorkforceShiftUti(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getWorkforceLOS(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverNSR(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverCount(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  // const getLeaverShiftUtlFunction = async (regionId, startDate, endDate) => {
  //   const bottomDeckData = await getLeaverShiftUtl(
  //     selected_client_id || userData.client_id,
  //     regionId,
  //     startDate,
  //     endDate
  //   );
  //   if (bottomDeckData && bottomDeckData.ok) {
  //     setLeaverShiftUtl(bottomDeckData?.data?.result?.rows);
  //   }
  // };

  const getLeaverLOSFunction = async (regionId, startDate, endDate) => {
    const bottomDeckData = await getLeaverLOS(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const getChartData = (regionId, startDate, endDate) => {
    if (isDashboardBottomCardVisible(selected_start_date, selected_end_date)) {
      if (is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) {
        Promise.all([
          getHeaderDataFunction(regionId, startDate, endDate),
          getSiteRatingDataFunction(regionId, startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getLeaverAnalysisDataFunction(regionId, startDate, endDate)
        ]);
      } else {
        Promise.all([
          getHeaderDataFunction(regionId, startDate, endDate),
          // getRatingDataFunction(regionId, startDate, endDate),
          getSiteRatingDataFunction(regionId, startDate, endDate),
          getAgencyRatingDataFunction(startDate, endDate),
          getClientRatingDataFunction(startDate, endDate),
          getLeaverAnalysisDataFunction(regionId, startDate, endDate),
          getActivityTopCardDataFunction(regionId, startDate, endDate),
          getWorkforceTopCardLOSDataFunction(regionId, startDate, endDate),
          getWorkforceTopCardShiftUtiDataFunction(regionId, startDate, endDate),
          getWorkforceTopCardPoolUtiDataFunction(regionId, startDate, endDate),
          getLeaverTopCardLOSFunction(regionId, startDate, endDate),
          getLeaverTopCardCountsFunction(regionId, startDate, endDate),
          getActivityBottomCardShiftUtiFunction(regionId, startDate, endDate),
          // getActivityBottomCardLostShiftFunction(regionId, startDate, endDate),
          getActivityBottomCardHeadCountFunction(regionId, startDate, endDate),
          getActivityBottomCardSpendFunction(regionId, startDate, endDate),
          getActivityBottomCardAverageHoursFunction(regionId, startDate, endDate),
          // getWorkforceDemoFunction(regionId, startDate, endDate),
          getWorkforceShiftUtiFunction(regionId, startDate, endDate),
          getWorkforceLOSFunction(regionId, startDate, endDate),
          getLeaverNSRFunction(regionId, startDate, endDate),
          getLeaverCountFunction(regionId, startDate, endDate),
          getLeaverLOSFunction(regionId, startDate, endDate)
          // getLeaverTopCardPoolUtiFunction(regionId, startDate, endDate)
        ]);
      }
    } else {
      Promise.all([
        getSiteRatingDataFunction(regionId, startDate, endDate),
        getAgencyRatingDataFunction(startDate, endDate),
        getClientRatingDataFunction(startDate, endDate),
        getActivityTopCardDataFunction(regionId, startDate, endDate),
        getWorkforceTopCardLOSDataFunction(regionId, startDate, endDate),
        getWorkforceTopCardShiftUtiDataFunction(regionId, startDate, endDate),
        getWorkforceTopCardPoolUtiDataFunction(regionId, startDate, endDate),
        getLeaverTopCardLOSFunction(regionId, startDate, endDate),
        getLeaverTopCardCountsFunction(regionId, startDate, endDate)
        // getLeaverTopCardPoolUtiFunction(regionId, startDate, endDate)
      ]);
    }
  };

  const previousData = async (index) => {
    if (index < regionData.length) {
      resetData();
      setRegionIdForDashboard(regionData[index].id);
      getChartData(regionData[index].id, selected_start_date, selected_end_date);
      setCurrentIndex(index);
    }
  };

  const nextData = (index) => {
    if (index > -1) {
      resetData();
      setRegionIdForDashboard(regionData[index].id);
      getChartData(regionData[index].id, selected_start_date, selected_end_date);
      setCurrentIndex(index);
    }
  };

  return (
    <>
      <>
        <Box mb={5}>
          {/* <Flex justifyContent="space-around"> */}
          <>
            <Card>
              <Text fontSize={25}>
                {(regionData && regionData.length > 0 && regionData[currentIndex].name) ||
                  'No Data Found!'}
              </Text>
            </Card>
            {/* </Flex> */}
            {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
              <CustomCarousel
                onNextButtonClick={() => nextData(currentIndex + 1)}
                onPreviousButtonClick={() => previousData(currentIndex - 1)}
                disablePreviousButtton={(regionData && regionData.length <= 0) || currentIndex <= 0}
                disableNextButtton={currentIndex >= regionData.length - 1}>
                <DashboardRatingCard
                  siteRatingData={siteRatingData}
                  agencyRatingData={agencyRatingData}
                  clientRatingData={clientRatingData}
                  headerData={headerData}
                  ratingData={ratingData}
                  leaversData={leaversData}
                  regionId={regionIdForDashboard}
                />
              </CustomCarousel>
            ) : (
              <DashboardRatingCard
                siteRatingData={siteRatingData}
                agencyRatingData={agencyRatingData}
                clientRatingData={clientRatingData}
                headerData={headerData}
                ratingData={ratingData}
                leaversData={leaversData}
                regionId={regionIdForDashboard}
              />
            )}
          </>
          {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
            <Box>
              <Box h="600px" display="flex" justifyContent="center">
                <Center fontSize={35}>Client Has Restricted View</Center>
              </Box>
            </Box>
          ) : (
            <Box>
              <CustomCarousel
                onNextButtonClick={() => nextData(currentIndex + 1)}
                onPreviousButtonClick={() => previousData(currentIndex - 1)}
                disablePreviousButtton={(regionData && regionData.length <= 0) || currentIndex <= 0}
                // disablePreviousButtton={currentIndex <= 0}
                disableNextButtton={currentIndex >= regionData.length - 1}>
                <>
                  <Box>
                    <DashboardTopCard
                      activityTopCardData={activityTopCardData}
                      workforceTopCardPoolUti={workforceTopCardPoolUti}
                      workforceTopCardShiftUti={workforceTopCardShiftUti}
                      workforceTopCardLOS={workforceTopCardLOS}
                      leaverTopCardLos={leaverTopCardLos}
                      leaversTopCardPoolUti={leaversTopCardPoolUti}
                      leaverTopCardCounts={leaverTopCardCounts}
                      isDataAvailable={!!(regionData && regionData.length > 0)}
                    />
                  </Box>

                  {isCurrentWeek && (
                    <Box>
                      <DashboardBottomCard
                        activityBottomCardShiftUtil={activityBottomCardShiftUtil}
                        activityBottomCardSpend={activityBottomCardSpend}
                        activityBottomCardAverageHours={activityBottomCardAverageHours}
                        workforceLOS={workforceLOS}
                        leaverLOS={leaverLOS}
                        leaverCount={leaverCount}
                        leaverNSR={leaverNSR}
                        activityBottomCardHeadCount={activityBottomCardHeadCount}
                        workforceShiftUti={workforceShiftUti}
                        isDataAvailable={!!(regionData && regionData.length > 0)}
                      />
                    </Box>
                  )}
                </>
                {/* <ChartCard
              activityTopCardData={activityTopCardData}
              workforceTopCardDemographics={workforceTopCardDemographics}
              workforceTopCardLOS={workforceTopCardLOS}
              workforceTopCardShiftUti={workforceTopCardShiftUti}
              workforceTopCardPoolUti={workforceTopCardPoolUti}
              leaverTopCardLos={leaverTopCardLos}
              leaverTopCardCounts={leaverTopCardCounts}
              leaverTopCardShiftUti={leaverTopCardShiftUti}
              activityBottomCardShiftUtil={activityBottomCardShiftUtil}
              activityBottomCardLostShift={activityBottomCardLostShift}
              activityBottomCardHeadCount={activityBottomCardHeadCount}
              activityBottomCardSpend={activityBottomCardSpend}
              activityBottomCardAverageHours={activityBottomCardAverageHours}
              workforceDemo={workforceDemo}
              workforceShiftUti={workforceShiftUti}
              workforceLOS={workforceLOS}
              leaverNSR={leaverNSR}
              leaverCount={leaverCount}
              leaverShiftUtl={leaverShiftUtl}
              leaverLOS={leaverLOS}
              isDataAvailable={!!(regionData && regionData.length > 0)}
            /> */}
              </CustomCarousel>
            </Box>
          )}
        </Box>
        {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <Box></Box>
        ) : (
          <StyledRegionContainer>
            {isPageLoader ? <Loader /> : null}
            <Card>
              {permission && permission.includes('create') && (
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => setModalOpen(true)}>
                  Add Region
                </Button>
              )}
              <ModalComponent
                isOpen={inModalOpan}
                onClose={() => onModalClose()}
                modalTitle={edit ? constants.REGION.EDIT_HEADER_TEXT : constants.REGION.HEADER_TEXT}
                modalContent={
                  <Box p="10px">
                    <Flex>
                      <StyledInputWrapper>
                        <InputComponent
                          isRequired
                          lable={constants.NAME}
                          placeholder={constants.NAME}
                          onChange={(e) => setData({ ...data, name: e })}
                          value={data.name}
                          validationObj={validations.name}
                          refresh={refresh}
                          maxLength="50"
                        />
                      </StyledInputWrapper>
                    </Flex>
                  </Box>
                }
                negative={
                  <Button
                    onClick={() => onModalClose()}
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black">
                    {constants.REGION.CANCEL_BUTTON_TEXT}
                  </Button>
                }
                positive={
                  <Button
                    onClick={() => saveRegion()}
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}>
                    {edit
                      ? constants.REGION.EDIT_UPDATE_BUTTON_TEXT
                      : constants.REGION.SAVE_BUTTON_TEXT}
                  </Button>
                }
              />

              {/* {regionData && regionData.length > 0 && ( */}
              <ClientSideDatatable
                pageSize={10}
                initialSortKey="id"
                column={column}
                row={regionData}
                pagination
                pages={[2, 5, 10, 15]}
                refresh={reload}
              />
              {/* )} */}
            </Card>
          </StyledRegionContainer>
        )}
      </>
    </>
  );
};

Region.propTypes = {};

export default Region;
