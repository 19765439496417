/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, useToast } from '@chakra-ui/react';
import LoginBox from '../../components/LoginBox';
import { userSignIn, getUserData } from '../../redux/action/user.action';
import constants, {
  LOGIN_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  PAGE_TITLE,
  USER_TYPE
} from '../../constants';
import { deepClone, validateForm } from '../../utils/helper';
import { getClientData } from '../../redux/action/client.action';
import { getAgencyData } from '../../redux/action/agency.action';
// import Loader from '../../components/Loader';

const LoginPage = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.LOGIN;
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validations, setValidations] = useState(deepClone(LOGIN_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);
  // const [isPageLoader, setIsPageLoader] = useState(false);

  const handleClick = async () => {
    const [validationObject, isFormValid] = validateForm(
      {
        email,
        password
      },
      validations
    );
    setValidations(validationObject);
    if (isFormValid) {
      // setIsPageLoader(true);
      const result = await dispatch(
        userSignIn({
          email,
          password
        })
      );
      if (result && result.ok) {
        const userInfo = await dispatch(await getUserData());
        const userTypeId = userInfo.data.user_details.user_type_id;

        // STEP: Retrieve login user profile detail
        switch (userTypeId) {
          case 1:
            // Super Admin
            break;
          case 2:
            try {
              dispatch(await getClientData(userInfo.data.user_details.client_id));
            } catch (error) {
              console.log(error);
            }
            // Client Admin
            break;
          case 3:
            try {
              dispatch(await getAgencyData(userInfo.data.user_details.agency_id));
            } catch (error) {
              console.log(error);
            }
            // Agency Admin
            break;
          case 4:
            try {
              dispatch(await getClientData(userInfo.data.user_details.client_id));
            } catch (error) {
              console.log(error);
            }
            // Region Admin
            break;
          case 5:
            try {
              dispatch(await getClientData(userInfo.data.user_details.client_id));
            } catch (error) {
              console.log(error);
            }
            // Site Admin
            break;
          default:
            break;
        }
        userTypeId === USER_TYPE.MESSAGE_ADMIN
          ? navigate(PATH.WORKER.TO)
          : navigate(PATH.DASHBOARD.TO);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
      // setIsPageLoader(true);
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box bg="main.blueBackground">
      {/* {isPageLoader ? <Loader /> : null} */}
      <LoginBox
        leftButtonText={constants.LOGIN}
        leftButtonClick={handleClick}
        rightButtonText={constants.FORGOT_PASSWORD}
        rightLinkTo="/forgot-password"
        type="LOGIN"
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        validations={validations}
        refresh={refresh}
      />
    </Box>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
